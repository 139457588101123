import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from './Images/Logo1.png';
import { useAuth } from './AuthContext';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown, Card } from 'react-bootstrap';
import { StyledCard, StyledH4, StyledFormControl, StyledForm, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import {useTracking} from 'react-tracking';
import { errorString } from './CriticalFunctions';



function SignInForm({onSignIn}) {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const firstInputRef = useRef(null);
    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        onSignIn({ 'email': email, 'password': password });
    };

    return (
        <>
            <StyledForm onSubmit={handleSubmit}>
                <StyledFormGroup controlId="formBasicEmail" className='mb-3'>
                    
                    <StyledFormControl
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={firstInputRef}
                    />
                </StyledFormGroup>
                <StyledFormGroup controlId="formBasicPassword">
                    
                    <StyledFormControl
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </StyledFormGroup>
                <Row className='justify-content-center mt-1'>
                    <Col md={6}>
                    <StyledButton variant="primary" type="submit" className="w-100">
                    Log In
                </StyledButton>

                    </Col>
                </Row>
                
            </StyledForm>
        </>

    );
}

function SignIn() {

    const themeMode = useTheme();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const {handleLogin} = useAuth();
    const {Track, trackEvent} = useTracking({event: "Sign In"});


    const handleSignIn = (credentials) => {
        console.log('handleSignIn called with credentials:', credentials);
        authenticateUser(credentials, setIsLoading, setError, handleLogin, trackEvent);
    };

    return (
        <Track>
        <div>
            <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                <Row className='justify-content-center'>
                    <Col className='col-lg-4 col-md-6'>
                    
                    <StyledCard>
                        <StyledCard.Body>
                            <Row className='justify-content-center mb-4'>
                                <Col md={10} className="text-center">
                                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>

                                </Col>
                            </Row>
                        <SignInForm onSignIn={handleSignIn} />
                        <div className='text-center mt-2'>
                            {isLoading && <StyledParagraph>Signing in...</StyledParagraph>}
                            {error && <StyledParagraph>{error}</StyledParagraph>}
                            <StyledParagraph className='mt-3'> Received an invite? <a href="/signup"> Create Your Account.</a></StyledParagraph>
                            <StyledParagraph>Forgot Password? <a href="/password-reset-email">Get Reset Link.</a></StyledParagraph>
                        </div>
                        </StyledCard.Body>
                    </StyledCard>
                    </Col>
                </Row>
            </Container>


        </div>
        </Track>
    );
}

async function authenticateUser(credentials, setIsLoading, setError, aboveLogin, trackEvent) {
    setIsLoading(true);
    setError(null);
    
    try {
        console.log("credentaisl");
        console.log(credentials);
        const response = await axios.post('/prospinity-auth/login/', credentials);
        trackEvent({email: credentials.email});
        aboveLogin(response);
    }
    catch (error) {
        console.log(error);
        console.error("Authentication error:", error);
        setError("Unable to login with provided credentials.");
        trackEvent({email: credentials.email, error: errorString(error)});
    }
    finally {
        setIsLoading(false);
    }
}

export default SignIn;
