import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
import logo from './Images/logo3.png';
import logo1 from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import SearchUsers from './SearchUsers';
import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { timeSince } from './CriticalFunctions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import TutorialModal from './TutorialModal';
import Notifications from './Notifications';
import { useTracking } from 'react-tracking';


    function NavbarLargeScreen({setShowReferModal, setWhyModalShow}) {
        const themeMode = useTheme();
        const navigate = useNavigate();
        const { trackEvent } = useTracking();
        const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

        const logOutNavigate = () => {
            handleLogout();
            navigate('/signing-out');
            
        }

        const getBaseFontSize = () => {
            // Get the font-size of the body element
            const fontSize = window.getComputedStyle(document.body).fontSize;
            // Parse the font size and return it as a number
            return parseFloat(fontSize);
        };

        const trackButtonClick = name => {
            console.log("tracking lower level");
            trackEvent({ event: 'Create Button Clicked'});
        }

        return (
            <StyledNavbar expand="sm" sticky='top'>
                {/* Brand and Toggle */}
                <Navbar.Brand className="d-flex ml-2 align-items-center" onClick={() => navigate('/home')} style={{cursor: 'pointer'}}>
                    <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className="d-inline-block align-top" alt="Logo" />
                </Navbar.Brand>
                <Container fluid>
                    <Row className="align-items-center w-100">
                        {/* Left Section - Search */}
                        <Col md={4} className="d-flex align-items-center">
                            <SearchUsers />
                        </Col>

                        {/* Middle Section - Main Links */}
                        <Col md={4} className="d-flex justify-content-between" >
                            {/* <StyledNavLink as={Link} to="home">
                                <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                    <div style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={faHome} fontSize={'1.5em'}   color={themeMode.onSurface}/>
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                        Home
                                    </div>
                                </div>
                            </StyledNavLink> */}

                            <StyledNavLink as={Link} to="meet">
                                <div className='d-flex flex-column justify-content-center align-content-center text-align-center meet-joyrride'>
                                    <div style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={faHandshake} fontSize={'1.5em'} color={themeMode.onSurface}/>
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                        Meet
                                    </div>
                                </div>
                            </StyledNavLink>

                            <StyledNavLink as={Link} to="create-pool" onClick={() => trackButtonClick("create")}>
                                <div className='d-flex flex-column justify-content-center align-content-center text-align-center create-joyrride'>
                                    <div style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={faPlus} fontSize={'1.5em'}  color={themeMode.onSurface}/>
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                        Create
                                    </div>
                                </div>
                            </StyledNavLink>
                            
                            

                            <StyledNavLink as={Link} to="payments">
                                <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                    <div style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={faCreditCard} fontSize={'1.5em'} color={themeMode.onSurface}/>
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                        Payments
                                    </div>
                                </div>
                            </StyledNavLink>

                            {/*<StyledNavLink as={Link} to="faqs">
                                <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                    <div style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={faQuestion} fontSize={'1.5em'} color={themeMode.onSurface}/>
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                        FAQs
                                    </div>
                                </div>
        </StyledNavLink>*/}
                        </Col>

                        {/* Right Section - Dropdowns and User Menu */}
                        <Col md={4} className="d-flex justify-content-end">

                            <div className="d-flex align-items-center">
             
                                {/*<StyledButton onClick={() => setWhyModalShow(true)} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme==='dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme==='dark' ? themeMode.surface : themeMode.onPrimary}}>Why?</StyledButton>*/}
                                <StyledButton className='invite-joyrride' onClick={() => setShowReferModal(true)} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme==='dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme==='dark' ? themeMode.surface : themeMode.onPrimary}}>Invite</StyledButton>
                                <Notifications />
                                <StyledDropdown >
                                    <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon profile-dropdown">
                                        {/*<FontAwesomeIcon icon={faUser} />*/}
                                        <div className='profile-dropdown-joyrride'>
                                        <ProfilePicture name={userFirstName + " " + userLastName} size={getBaseFontSize() * 3} graduation_date={userGraduationDate} picture_link={profilePictureLink}/>
                                        </div>
                                    </StyledDropdown.Toggle>
                                    <StyledDropdown.Menu align={'end'}>
                                        <StyledDropdown.Item as={Link} to={"user/" + userSlug} >
                                            <Row>
                                                <Col xs={2}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Col>
                                                <Col>Profile</Col>

                                            </Row>
                                        </StyledDropdown.Item>
                                    
                                        <StyledDropdown.Item as={Link} to="settings">
                                            <Row>
                                                <Col xs={2}><FontAwesomeIcon icon={faGear}></FontAwesomeIcon></Col>
                                                <Col>Settings</Col>

                                            </Row>
                                        </StyledDropdown.Item>

                                        <StyledDropdown.Item as={Link} to="faqs">
                                            <Row className='faqs-joyrride'>
                                                <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
                                                <Col>FAQs</Col>

                                            </Row>
                                        </StyledDropdown.Item>

                                        {/*
                                        <StyledDropdown.Item href={"mailto:support@prospinity.com"}>
                                            <Row>
                                                <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
                                                <Col>Support</Col>

                                            </Row>
        </StyledDropdown.Item>*/}
                                        <StyledDropdown.Item onClick={logOutNavigate}>
                                            <Row>
                                                <Col xs={2}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></Col>
                                                <Col>Logout</Col>

                                            </Row>
                                        </StyledDropdown.Item>
                                    </StyledDropdown.Menu>
                                </StyledDropdown>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </StyledNavbar>);
    }


export default NavbarLargeScreen;