import { useState, useRef, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Button, Row, Col, Container, InputGroup, Dropdown, Card } from 'react-bootstrap';
import logo from './Images/Logo1.png';
import axios from 'axios';
import { StyledCard, StyledFormControl, StyledForm, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useNavigate } from 'react-router-dom';
import EmailVerificationSent from './EmailVerificationSent';
import { useParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { errorString } from './CriticalFunctions';
import { useAuth } from './AuthContext';
import PasswordStrengthBar from 'react-password-strength-bar';


function SignUp() {
    const themeMode = useTheme();
    const { key } = useParams();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [prospinityKey, setProspinityKey] = useState(key || '');
    const [sent, setSent] = useState(false);
    const navigate = useNavigate();
    const { trackEvent, Track } = useTracking({ event: "Sign Up" });
    const { handleLogin } = useAuth();
    const [errors, setErrors] = useState({ email: false, password1: false, password2: false, non_field_errors: false });



    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);


    const firstInputRef = useRef(null);
    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);



    const handleEmailSubmit = (e) => {
        // Typically, you'd validate the email here or send it to an API before moving to the next step.
        e.preventDefault();
        authenticateInitialSignUp(email, password, verifyPassword, prospinityKey, setIsLoading, setMessage);
    };

    async function authenticateInitialSignUp(email, pw, vpw, proskey, setIsLoading, setMessage) {
        setIsLoading(true);
        setMessage(null);
        let response = null;


        try {
            const credentials = { 'email': email, 'password1': pw, 'password2': vpw };
            console.log(credentials);
            response = await axios.post('/prospinity-auth/registration/', credentials);
            setSent(true);
            //navigate("/verification-link-sent");
            //setMessage("Please check your email for a verification link.")
            console.log(response.data);
            trackEvent({ email: email });
            handleLogin(response);
        }
        catch (error) {
            if (error.response && error.response.data) {
                // Construct the error list JSX and pass it to setMessage
                let tempErrors = { password1: false, email: false, password2: false, non_field_errors: false }
                for (const [key, value] of Object.entries(error.response.data)) {
                    tempErrors = { ...tempErrors, [key]: true };
                }
                setErrors(tempErrors);

                console.log(error);
                console.error("Authentication error:", error);
                if (tempErrors.non_field_errors) {
                    console.log("in this if statement");
                    setMessage(error.response.data.non_field_errors);
                }

            }
            else {
                setMessage("Unable to sign up with provided information.");
            }
            trackEvent({ email: email, error: errorString(error) });
            /*console.log(Object.values(error.response.data)[0][0]);
            console.log(error);
            setMessage(Object.values(error.response.data)[0][0]);
            trackEvent({email: email, error: errorString(error) });*/
        }
        finally {
            setIsLoading(false);

        }
    }


    return (
        <Track>
            {sent ? <EmailVerificationSent><StyledParagraph className='mt-3'>Verification link sent. Please check your email.</StyledParagraph></EmailVerificationSent> :
                <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                    <Row className='justify-content-center'>
                        <Col className='col-lg-4 col-md-6'>

                            <StyledCard>
                                <StyledCard.Body>
                                    <Row className='justify-content-center mb-4'>
                                        <Col md={10} className="text-center">
                                            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
                                        </Col>
                                    </Row>

                                    <StyledForm onSubmit={(e) => handleEmailSubmit(e)}>
                                        <StyledFormGroup controlId="formBasicEmail">
                                            <StyledFormControl
                                                type="email"
                                                placeholder="University Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                ref={firstInputRef}
                                                hasError={errors.email}
                                                onClick={() => setErrors({ ...errors, email: false })}
                                            />
                                            {errors.email ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid university email.</StyledParagraph> : <></>}

                                        </StyledFormGroup>
                                        {/*<StyledFormGroup controlId="formVerifyPassword">
                                    <StyledFormLabel>Prospinity Key</StyledFormLabel>
                                    <StyledFormControl
                                        type="password"
                                        placeholder="Enter Your Prospinity Key"
                                        value={prospinityKey}
                                        onChange={(e) => setProspinityKey(e.target.value)}
                                    />
    </StyledFormGroup>*/}

                                        <StyledFormGroup controlId="formBasicPassword">
                                            <StyledFormControl
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                hasError={errors.password1}
                                                onClick={() => setErrors({ ...errors, password1: false })}
                                            />
                                            {errors.password1 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a strong password.</StyledParagraph> : <></>}


                                        </StyledFormGroup>
                                        {password.length != 0 ?
                                            <PasswordStrengthBar password={password} /> : <></>}
                                        <StyledFormGroup controlId="formVerifyPassword">
                                            <StyledFormControl
                                                type="password"
                                                placeholder="Verify Password"
                                                value={verifyPassword}
                                                onChange={(e) => setVerifyPassword(e.target.value)}
                                                hasError={errors.password2}
                                                onClick={() => setErrors({ ...errors, password2: false })}
                                            />
                                            {errors.password2 ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please verify your password.</StyledParagraph> : <></>}

                                        </StyledFormGroup>

                                        <StyledButton variant="primary" type="submit" className="mt-1 w-100">
                                            Get Started
                                        </StyledButton>
                                    </StyledForm>
                                    <div className='text-center'>
                                        {isLoading && <StyledParagraph className='mt-3'>Signing in...</StyledParagraph>}
                                        {message && <StyledParagraph className='mt-3'>{message}</StyledParagraph>}
                                        <StyledParagraph className='mt-3'> Have an account? <a href="/signin"> Log In.</a></StyledParagraph>
                                        <StyledParagraph className='mt-3'> Don't have an invite? <a href="https://prospinity.com"> Apply here.</a></StyledParagraph>
                                        <StyledParagraph className='mt-3'> By joining the platform, you consent to our <a href="/privacypolicy"> Privacy Policy</a> and <a href="/termsofuse"> Terms of Use</a>.</StyledParagraph>
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </Col>
                    </Row>

                </Container>}
        </Track>
    );

}




export default SignUp;




