import React, { useState, useEffect, useRef } from 'react';
import { CallingState, StreamCall, StreamVideo, StreamVideoClient, useCall, useCallStateHooks, User, StreamTheme, SpeakerLayout, CallControls, VideoPreview } from '@stream-io/video-react-sdk';
import { useParams } from 'react-router-dom';
import { myAxiosInstance } from './axiosConfig';
import Loading from './Loading';
import { Row, Col, Container } from 'react-bootstrap';
import { StyledCard, StyledH5, StyledButton, StyledParagraph, StyledRangeInput} from './StyledBootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { useTheme } from 'styled-components';
import ScheduleSelector from 'react-schedule-selector';
import Scheduler from './Scheduler';
import { useOutletContext } from 'react-router-dom';
import { init } from 'mixpanel-browser';
import { useAuth } from './AuthContext';
import MeetingTimer from './MeetingTimer';
//import { Lobby } from './Lobby';




function MeetingPage() {
    const { slug } = useParams();

    const { userSlug, userFirstName, chatID, chatToken } = useAuth();
    const apiKey = process.env.REACT_APP_STREAM_API_KEY;


    const themeMode = useTheme();

    const cardRef = useRef(null);
    const { showBoundary } = useErrorBoundary();

    const [isBottomLoading, setIsBottomLoading] = useState(true); //CHANGE TO TRUE
    const [bottomReload, setBottomReload] = useState(0);

    const [dateOptions, setDateOptions] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);

    const [proposingNew, setProposingNew] = useState(false);
    const [proposeNewTimesButtonLoading, setProposeNewTimesButtonLoading] = useState(false);
    const [proposingNewDateOptions, setProposingNewDateOptions] = useState([]);

    const [meetingData, setMeetingData] = useState(null);

    const [pastTime, setPastTime] = useState(false);


    const [meetingButtonLoading, setMeetingButtonLoading] = useState(false);


    const { setIsMeetingStarted, isMeetingStarted, callIDRef, clientRef, callRef } = useOutletContext();
    const [joiningButtonLoading, setJoiningButtonLoading] = useState(false);




    const joinMeeting = async () => {
        try {
            setJoiningButtonLoading(true);
            setMeetingButtonLoading(true);
            if (callRef.current) {
                await callRef.current.join({ create: true });  // Join the call only once
                console.log("Call joined successfully", callRef.current);
                console.log("Call State right after joining", callRef.current.state.callingState);
                setIsMeetingStarted(true);  // Update meeting state without reinitializing the call
            }
        } catch (error) {
            console.error("Error joining the call", error);
        }
        finally
        {
            setJoiningButtonLoading(false);
            setMeetingButtonLoading(false);
        }
    };




    const handleMeetingButtonClick = async () => {
        if (meetingData.user_meeting_status === "you_received" || meetingData.user_meeting_status === "other_user_rescheduled") {
            try {
                setMeetingButtonLoading(true);
                console.log("date options", meetingData.date_options.date_options);
                console.log("selected time sending", { scheduled_day: selectedTime[0].toISOString() });
                const response = await myAxiosInstance.put('/api/accept-meeting/' + slug + "/", { scheduled_day: selectedTime[0].toISOString() });
                setBottomReload(bottomReload + 1);

            }
            catch (error) {
                console.error('Error sending portfolio request', error.response.data);
            }
            finally {
                setMeetingButtonLoading(false);
            }
        }
        if (meetingData.user_meeting_status === "you_requested" || meetingData.user_meeting_status === "you_rescheduled" || (meetingData.user_meeting_status === "confirmed" && !pastTime)) {
            setProposingNew(true);
        }
        if (meetingData.user_meeting_status === "confirmed" && pastTime)
        {
            joinMeeting();
        }

    };



    const handleProposeNewTimesButtonClick = async () => {
        try {
            setProposeNewTimesButtonLoading(true);
            let data = proposingNewDateOptions.map((timeSlot) => (timeSlot.toISOString()));                //console.log({receiving_user: fullUserData.email, date_options: {"date_options" : data}});
            const response = await myAxiosInstance.put('/api/manage-meeting/' + slug + "/", { date_options: { "date_options": data } });
            setBottomReload(bottomReload + 1);

        }
        catch (error) {
            console.error('Error sending portfolio request', error.response.data);
        }
        finally {
            setProposeNewTimesButtonLoading(false);
        }

    };




        const initializeCall = async (id) => {
            if (!clientRef.current) {
                // Initialize StreamClient only once
                const client = StreamVideoClient.getOrCreateInstance({ apiKey, user: { id: chatID, name: userFirstName }, token: chatToken });
                clientRef.current = client;  // Store client in ref to persist between renders
            }

            // Reinitialize Call when callID changes
            console.log("callIDRef", callIDRef.current);
            console.log("id", id);
            if ((!callIDRef.current || callIDRef.current !== id)) {
                callIDRef.current = id;
                console.log("after updating", callIDRef.current);
                const callInstance = clientRef.current.call('default', id);  // Use new callID to create new call
                callRef.current = callInstance;  // Update callRef to new call instance
                console.log("Call initialized with ID", id);
            }
        };

        useEffect(() => {
            console.log("Component mounted");
            return () => {
                console.log("Component unmounted");
            };
        }, []);

    useEffect(() => {
        const getMeetingDetails = async () => {
            try {
                setIsBottomLoading(true);
                setProposingNew(false);
                setSelectedTime(null);
                console.log("before retrieving data");
                const response = await myAxiosInstance.get('/api/meetings/' + slug + '/');
                console.log(response.data);

                setMeetingData(response.data);
                if (response.data.user_meeting_status === "confirmed") {
                    //setCallID(response.data.call_id);
                    initializeCall(response.data.call_id);

                    const now = new Date();
                    const deadlineDate = new Date(response.data.scheduled_day);
                    const timeDiff = deadlineDate - now;
                    if (timeDiff <= 0)
                    {
                        setPastTime(true);
                    }
                }


                const tempList1 = response.data.date_options.date_options;
                const tempList2 = tempList1.map((isoString) => (new Date(isoString)));



                console.log("date options", tempList2);
                setDateOptions(tempList2);

                //setSchedule(response.data)

            } catch (error) {
                console.error('Error fetching meeting details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getMeetingDetails();


    }, [slug, bottomReload]);

    return (

        isBottomLoading ? <Loading /> :

            <StreamVideo client={clientRef.current}>
                <StreamCall call={callRef.current}>
                    {isMeetingStarted ?
<>
                        {/*<StyledCard>
                            <StyledCard.Body>
                                <MyUILayout />
                            </StyledCard.Body>
                        </StyledCard>*/}
                        <TestVideoCall meetingData={meetingData}/>
                        </>
                        :


                        <Row className='justify-content-center'>
                            <Col className='col-lg-6 col-md-8'>
                                <StyledCard ref={cardRef} className='profile-joyrride'>
                                    <StyledCard.Body>
                                        
                                        <Container fluid="sm" className="d-flex flex-column justify-content-center">
                                            {!proposingNew ? <>
                                                {callRef.current && pastTime ? 
                                        <Lobby /> : <></>}
                                                <Row className='align-items-center mb-2'>
                                                    <Col className='text-left d-flex justify-content-between'>

                                                        <StyledH5>
                                                            {meetingData.user_meeting_status === "you_received" ? <>{meetingData.other_user.first_name} wants to add you to their portfolio!</> : <></>}
                                                            {meetingData.user_meeting_status === "you_requested" ? <>You requested to add {meetingData.other_user.first_name} to your portfolio.</> : <></>}
                                                            {meetingData.user_meeting_status === "confirmed" ? <>Meeting confirmed with {meetingData.other_user.first_name}.</> : <></>}
                                                            {meetingData.user_meeting_status === "other_user_rescheduled" ? <>{meetingData.other_user.first_name} wants to reschedule!</> : <></>}
                                                            {meetingData.user_meeting_status === "you_rescheduled" ? <>You requested to reschedule with {meetingData.other_user.first_name}.</> : <></>}
                                                        </StyledH5>


                                                    </Col>
                                                </Row>
                                                <Row className='align-items-center mb-2'>
                                                    <Col className='text-left d-flex justify-content-between'>
                                                        <StyledParagraph>
                                                            {meetingData.user_meeting_status === "you_received" ? <>Here's when they are available. Pick a time below, or propose new ones.</> : <></>}
                                                            {meetingData.user_meeting_status === "you_requested" ? <>Here's when you said you were available to meet.</> : <></>}
                                                            {meetingData.user_meeting_status === "confirmed" && !pastTime? <>You are meeting with {meetingData.other_user.first_name} on <strong>{new Date(meetingData.scheduled_day).toLocaleDateString()}</strong> at <strong>{new Date(meetingData.scheduled_day).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}</strong>. 
                                                            Come back to this page to join your meeting.</> : <></>}
                                                            {meetingData.user_meeting_status === "confirmed" && pastTime? <>Your meeting with {meetingData.other_user.first_name} has started! Join the call below.</> : <></>}
                                                            {meetingData.user_meeting_status === "other_user_rescheduled" ? <>Here's when they are available. Pick a time below, or propose new ones.</> : <></>}
                                                            {meetingData.user_meeting_status === "you_rescheduled" ? <>Here's when you said you were available. Click the Edit button below if you need to update your availability.</> : <></>}
                                                        </StyledParagraph>
                                                    </Col>
                                                </Row>
                                                {meetingData.user_meeting_status === "you_received" ? <Scheduler status={"pick"} availability={dateOptions} selectedTime={selectedTime} setSelectedTime={setSelectedTime} /> : <></>}
                                                {meetingData.user_meeting_status === "you_requested" ? <Scheduler status={"display"} proposedTimes={dateOptions} /> : <></>}
                                                {meetingData.user_meeting_status === "confirmed" ? <div><MeetingTimer meetingTime={meetingData.scheduled_day} onTime={() => {setBottomReload(bottomReload + 1)}}/></div> : <></>}
                                                {meetingData.user_meeting_status === "other_user_rescheduled" ? <Scheduler status={"pick"} availability={dateOptions} selectedTime={selectedTime} setSelectedTime={setSelectedTime} /> : <></>}
                                                {meetingData.user_meeting_status === "you_rescheduled" ? <Scheduler status={"display"} proposedTimes={dateOptions} /> : <></>}

                                                <Row className='d-flex justify-content-center mt-3'>
                                                    <Col md={8} className='d-flex justify-content-center'>
                                                        {meetingData.user_meeting_status === "you_received" || meetingData.user_meeting_status === "other_user_rescheduled" ?
                                                            <>
                                                                <StyledButton style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }} className='mr-1' onClick={() => setProposingNew(true)}>Propose New Times</StyledButton>

                                                                <StyledButton onClick={handleMeetingButtonClick} className='ml-1'>
                                                                    {meetingButtonLoading ? <Loading /> : <>
                                                                        {meetingData.user_meeting_status === "you_received" ? <>Confirm Time</> : <></>}
                                                                        {meetingData.user_meeting_status === "you_requested" ? <>Edit Availability</> : <></>}
                                                                        {meetingData.user_meeting_status === "confirmed" ? <>Reschedule</> : <></>}
                                                                        {meetingData.user_meeting_status === "other_user_rescheduled" ? <>Confirm Time</> : <></>}
                                                                        {meetingData.user_meeting_status === "you_rescheduled" ? <>Edit Availability</> : <></>}</>}
                                                                </StyledButton>
                                                            </> :
                                                            <StyledButton onClick={handleMeetingButtonClick} className='ml-1'>
                                                                {meetingButtonLoading ? <Loading /> : <>
                                                                    {meetingData.user_meeting_status === "you_received" ? <>Confirm Time</> : <></>}
                                                                    {meetingData.user_meeting_status === "you_requested" ? <>Edit Availability</> : <></>}
                                                                    {meetingData.user_meeting_status === "confirmed" && !pastTime ? <>Reschedule</> : <></>}
                                                                    {meetingData.user_meeting_status === "confirmed" && pastTime ? <>Join Now</> : <></>}
                                                                    {meetingData.user_meeting_status === "other_user_rescheduled" ? <>Confirm Time</> : <></>}
                                                                    {meetingData.user_meeting_status === "you_rescheduled" ? <>Edit Availability</> : <></>}</>}
                                                            </StyledButton>}

                                                    </Col>
                                                </Row></> :
                                                <>
                                                    <Row className='align-items-center mb-2'>
                                                        <Col className='text-left d-flex justify-content-between'>

                                                            <StyledH5>
                                                                {meetingData.user_meeting_status === "you_requested" ? <>Editing your availability to meet {meetingData.other_user.first_name}...</> : <></>}
                                                                {meetingData.user_meeting_status === "you_rescheduled" ? <>Editing your availability to meet {meetingData.other_user.first_name}...</> : <></>}
                                                                {meetingData.user_meeting_status === "you_received" ? <>Proposing new times to meet {meetingData.other_user.first_name}...</> : <></>}
                                                                {meetingData.user_meeting_status === "other_user_rescheduled" ? <>Proposing new times to {meetingData.other_user.first_name}...</> : <></>}
                                                                {meetingData.user_meeting_status === "confirmed" ? <>Rescheduling with {meetingData.other_user.first_name}...</> : <></>}
                                                            </StyledH5>
                                                        </Col>
                                                    </Row>
                                                    <Row className='align-items-center mb-2'>
                                                        <Col className='text-left d-flex justify-content-between'>

                                                            <StyledParagraph>
                                                                Propose new times below:
                                                            </StyledParagraph>
                                                        </Col>
                                                    </Row>
                                                    <Scheduler status={"propose"} proposedTimes={proposingNewDateOptions} setProposedTimes={setProposingNewDateOptions} />

                                                    <Row className='d-flex justify-content-center mt-3'>
                                                        <Col md={6} className='d-flex justify-content-center'>

                                                        <StyledButton style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }} className='mr-1' onClick={() => setProposingNew(false)}>Back</StyledButton>

                                                            <StyledButton onClick={handleProposeNewTimesButtonClick} className='ml-1'>
                                                                {proposeNewTimesButtonLoading ? <Loading /> : <>
                                                                    {meetingData.user_meeting_status === "you_requested" ? <>Update Availability</> : <></>}
                                                                    {meetingData.user_meeting_status === "you_rescheduled" ? <>Update Availability</> : <></>}
                                                                    {meetingData.user_meeting_status === "you_received" ? <>Send Times</> : <></>}
                                                                    {meetingData.user_meeting_status === "other_user_rescheduled" ? <>Send Times</> : <></>}
                                                                    {meetingData.user_meeting_status === "confirmed" ? <>Reschedule</> : <></>}
                                                                </>}
                                                            </StyledButton>

                                                        </Col>
                                                    </Row></>
                                            }
                                        </Container>
                                    </StyledCard.Body>
                                </StyledCard>
                            </Col>
                        </Row>}</StreamCall>
            </StreamVideo>

    )


}




const TestVideoCall = ({meetingData}) => {


    const containerRef = useRef(null);
    const themeMode = useTheme();
    const { useCallCallingState } = useCallStateHooks();
    const callingState = useCallCallingState();
  
    useEffect(() => {
      const updateLayout = () => {
  
        const viewportHeight = window.innerHeight;
  
  
        if (containerRef.current) {
          // Push content below the navbar
          containerRef.current.style.minHeight = `${viewportHeight}px`; // Ensure it fills the remaining screen height
        }
      };
  
      updateLayout(); // Adjust layout on mount
      window.addEventListener('resize', updateLayout); // and on window resize
  
      return () => window.removeEventListener('resize', updateLayout); // Cleanup
    }, []); // Run once on mount
  
  
  
    return (
      <>
        <Container ref={containerRef} fluid className='d-flex flex-column justify-content-center h-100' style={{ backgroundColor: themeMode.background }}>
          <Row className='justify-content-center'>
            {callingState === CallingState.JOINED ?
            <Col className='col-lg-2 col-md-1'>
              <PromptComponent />
            </Col> : <></>}
            <Col className='col-lg-8 col-md-10'>
  
            <StyledCard>
                            <StyledCard.Body>
                                <MyUILayout meetingData={meetingData}/>
                            </StyledCard.Body>
                        </StyledCard>
              
  
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  
  
const PromptComponent = () => {
    const [promptNumber, setPromptNumber] = useState(0);
;
  
    const prompts = ['Welcome to your Prospinity portfolio meeting! Use this section to guide the meeting, clicking the button below to move onto the next prompt.', 'Introduce yourself if you haven\'t already.', 'That\'s it from our side in terms of prompts. Feel free to talk more or, if you don\'t have anything more to say (which is fair), say goodbye and leave the call.'];
    const themeMode = useTheme();
  
    return (
    
      <StyledCard>
        <StyledCard.Body>
  
    
          {prompts[promptNumber]}
  
  
          {promptNumber == 0 ?
            <StyledButton onClick={() => setPromptNumber(promptNumber + 1)} className='w-100 mt-4'>
              Get Started
            </StyledButton>
            : <></>}
          {promptNumber != 0 ?
            <div className='d-flex justify-content-center mt-4'>
              <StyledButton onClick={() => setPromptNumber(promptNumber - 1)} className='mr-2 w-50' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
                Back
              </StyledButton>
              {promptNumber < prompts.length-1 ?
  
                <StyledButton onClick={() => setPromptNumber(promptNumber + 1)} className='ml-2 w-50'>
                  Next
                </StyledButton> :
  
  
  
                <></>}
  
            </div>
  
            : <></>}
  
        </StyledCard.Body>
  
      </StyledCard>
    );
  
}

const FeedbackForm = ({meetingData}) => {
    const [usefulnessRating, setUsefulnessRating] = useState(5);
    const [poolLikelihoodRating, setPoolLikelihoodRating] = useState(5);
  
    const handleSubmitFeedback = () => {
      console.log("Usefulness Rating: ", usefulnessRating);
      console.log("Likelihood to Success Pool: ", poolLikelihoodRating);
      // Submit feedback to the server or handle it however you need
    };
  
    return (
      <StyledCard>
        <StyledCard.Body>
        <div className='d-flex justify-content-center'>
          <StyledH5>{meetingData.other_user.first_name} has been added to your portfolio!</StyledH5>
        </div>
          <StyledParagraph>
            Your feedback helps us tune our algorithm to better match you with people who fit your goals and preferences for success pools. Please take a moment to share your thoughts on this meeting.
          </StyledParagraph>
        
  
          <div className="feedback-section">
            <StyledParagraph>How useful was this meeting?</StyledParagraph>
            <StyledRangeInput
              min="0"
              max="10"
              value={usefulnessRating}
              onChange={(e) => setUsefulnessRating(e.target.value)}
            />
            <StyledParagraph>{usefulnessRating}/10</StyledParagraph>
          </div>
  
          <div className="feedback-section mt-4">
            <StyledParagraph>How likely would you be to success pool with {meetingData.other_user.first_name}?</StyledParagraph>
            <StyledRangeInput
              min="0"
              max="10"
              value={poolLikelihoodRating}
              onChange={(e) => setPoolLikelihoodRating(e.target.value)}
            />
            <StyledParagraph>{poolLikelihoodRating}/10</StyledParagraph>
          </div>
  
          <div className="submit-section mt-4 d-flex justify-content-center">
            <StyledButton onClick={handleSubmitFeedback}>Submit Feedback</StyledButton>
          </div>
        </StyledCard.Body>
      </StyledCard>
    );
  };

export const MyUILayout = ({meetingData}) => {
    const { useCallCallingState } = useCallStateHooks();
    const callingState = useCallCallingState();
   
    console.log("Current Calling State:", callingState);
  
    if (callingState === CallingState.LEFT) {
      return <>
      <FeedbackForm meetingData={meetingData}/>
      </>;
    }
    else if (callingState === CallingState.RECONNECTING_FAILED || callingState === CallingState.IDLE) {
        return <div className='d-flex justify-content-center'><StyledButton onClick={() => window.location.reload()}>Try Reconnecting</StyledButton></div>;
    }
    else if (callingState === CallingState.JOINED)
    {
        return (
            <StreamTheme>
              <SpeakerLayout participantsBarPosition='bottom' />
              <CallControls />
            </StreamTheme>
          );

    }
    else {
        return <div className='d-flex justify-content-center'><Loading /></div>;
      }
    
  };


const Lobby = () => {
    const { useCameraState } = useCallStateHooks();
    const { camera, mediaStream } = useCameraState();
    const videoRef = useRef(null); // Use ref to access the video element

    const [isCameraEnabled, setIsCameraEnabled] = useState(false);

    useEffect(() => {
        const enableCamera = async () => {
            await camera.enable(); // Enable the camera
            setIsCameraEnabled(true);
        };
        enableCamera();
    }, [camera]);

    useEffect(() => {
        // Attach the media stream to the video element once it's available
        if (videoRef.current && mediaStream) {
            videoRef.current.srcObject = mediaStream;
        }
    }, [mediaStream]);

    return (
        isCameraEnabled && mediaStream ? (
            <video ref={videoRef} autoPlay muted playsInline style={{ width: '100%', height: 'auto', borderRadius: '1rem' }} className='mb-3'/>
        ) : <div className='d-flex justify-content-center'><Loading /></div>
    );
};



export default MeetingPage;