import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { myAxiosInstance } from './axiosConfig';
import Loading from './Loading';
import { Row, Col, Container } from 'react-bootstrap';
import { StyledCard, StyledH5, StyledButton } from './StyledBootstrap';
import { useErrorBoundary } from "react-error-boundary";
import ScheduleSelector from 'react-schedule-selector';
import { useTheme } from 'styled-components';
//display without selecting, propose times, select time

function Scheduler({ status, availability, selectedTime, setSelectedTime, proposedTimes, setProposedTimes }) {

    const themeMode = useTheme();

    const handleProposedTimesChange = newSchedule => {
        setProposedTimes(newSchedule);
    }

    const handleDisplayTimesChange = newSchedule => {
        console.log("hey");
    }

    const handleTimeChange = (newTime) => {
        try {

            console.log("selectedTime[0]", selectedTime);
            console.log("newTime[0]", newTime);
            if (selectedTime.length == 0) //setting completely new time
            {
                if (availability.some((availableTime) => (availableTime.toString() === newTime[0].toString()))) //maybe these not needed
                {
                    setSelectedTime([newTime[0]]);
                }

            }
            else if (!(newTime.length === 0) && (selectedTime.length === 1 && selectedTime[0].toString() !== newTime[1].toString())) //time already selected, switching
            {
                if (availability.some((availableTime) => (availableTime.toString() === newTime[1].toString()))) {
                    setSelectedTime([newTime[1]]);
                }
                setSelectedTime([newTime[1]]);
            }
            else {
                setSelectedTime([]); // Deselect if the same time is clicked again
            }

        }
        catch (error) {
            console.log(error);
        }

    };



    const renderDateCellPropose = (time, selected, innerRef) => {
        // Check if the current time is in the available times list

        const handleMouseEnter = (event) => {
            
            event.target.style.backgroundColor = themeMode.primaryVariant; // Dark green on hover
            
        };

        const handleMouseLeave = (event) => {

            if (selected)
            {
            event.target.style.backgroundColor = themeMode.primary; 
            }
            else{
                event.target.style.backgroundColor = themeMode.background;
            }

                // Revert to original color
            
        };



        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: selected ? themeMode.primary : themeMode.background, // Neon green for available slots
            cursor: 'pointer', // Allow interaction with available slots only
            transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}
                onMouseEnter={handleMouseEnter} // Hover starts
                onMouseLeave={handleMouseLeave} // Hover ends

            />
        );
    };


    const renderDateCellDisplay = (time, selected, innerRef) => {
        // Check if the current time is in the available times list

        


        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: selected ? themeMode.primary : themeMode.background, // Neon green for available slots
            cursor: 'not-allowed', // Allow interaction with available slots only
            transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}


            />
        );
    };


    const renderDateCellPick = (time, selected, innerRef) => {
        // Check if the current time is in the available times list
        const isAvailable = availability.some(
            (availableTime) => availableTime.getTime() === time.getTime()
        );

        const handleMouseEnter = (event) => {
            if (isAvailable) {
                event.target.style.border = '2px solid #000'; 
            }
        };

        const handleMouseLeave = (event) => {
            if (isAvailable) {
                event.target.style.border = selected ? '2px solid #000' : '1px solid #ddd'; 
            }
        };



        // Style for available time slots (highlighted)
        const availableStyle = {
            backgroundColor: isAvailable ? themeMode.primary : themeMode.background, // Neon green for available slots
            border: selected ? '2px solid #000' : '1px solid #ddd', // Highlight border for selected slot
            cursor: isAvailable ? 'pointer' : 'not-allowed', // Allow interaction with available slots only
            transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        };

        return (
            <div
                ref={innerRef}
                style={{
                    ...availableStyle,
                    width: '100%',
                    height: '100%',
                }}
                onMouseEnter={handleMouseEnter} // Hover starts
                onMouseLeave={handleMouseLeave} // Hover ends
            />
        );
    };


    return (
        status === "pick" ?
            <ScheduleSelector
                selection={selectedTime}
                numDays={7}
                minTime={8}
                maxTime={22}
                hourlyChunks={1}
                onChange={handleTimeChange}
                timeFormat={'h:mma'}
                renderDateCell={renderDateCellPick}
            /> : status === "propose" ?
                <ScheduleSelector
                    selection={proposedTimes}
                    numDays={7}
                    minTime={8}
                    maxTime={22}
                    hourlyChunks={1}
                    onChange={handleProposedTimesChange}
                    timeFormat={'h:mma'}
                    renderDateCell={renderDateCellPropose}
                />

                : <ScheduleSelector
                    selection={proposedTimes}
                    numDays={7}
                    minTime={8}
                    maxTime={22}
                    hourlyChunks={1}
                    onChange={handleDisplayTimesChange}
                    timeFormat={'h:mma'}
                    renderDateCell={renderDateCellDisplay}
                />


    )


}

export default Scheduler;