//graceful error handling for unknown user
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Container, Card, Button, Image, Table, Form } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import Edit from './UserProfileEdit';
import { ProfilePicture } from './MiscComponents';
import { PatchCheck, Pencil } from 'react-bootstrap-icons';
import { stringToColor, formatGraduationDateSummer } from './CriticalFunctions';
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard, StyledH5 } from './StyledBootstrap';
import { myAxiosInstance } from './axiosConfig';
import { BioModal } from './BioModal';
import './UserProfile.css';
import ProfilePictureUpload from './ProfilePictureUpload';
import Loading from './Loading';
import { Linkedin, Envelope, Telegram } from 'react-bootstrap-icons';
import { StyledButton } from './StyledBootstrap';
import ScheduleSelector from 'react-schedule-selector';
import Scheduler from './Scheduler';

function UserProfile() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [fullUserData, setFullUserData] = useState(null);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);

    const [addToPortfolio, setAddToPortfolio] = useState(false);

    const { showBoundary } = useErrorBoundary();

    const [showBioModal, setShowBioModal] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [modalEditData, setModalEditData] = useState(null);

    const [pictureSize, setPictureSize] = useState(0);
    const cardRef = useRef(null);

    const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

    const [schedule, setSchedule] = useState([]);
    const [sendPortfolioRequestLoading, setSendPortfolioRequestLoading] = useState(false);


    /*const handleChange = newSchedule => {
    console.log(newSchedule);
    console.log(newSchedule[0]);
    setSchedule(newSchedule);
    }*/
    const [buttonIndex, setButtonIndex] = useState(6);
    const buttonLabels = ["Add to Portfolio", "Sent Request", "View Request", "Meeting Confirmed", "Sent Reschedule Request", "View Reschedule Request"];

    const bubbleStyle = (color) => ({
        display: 'inline-block',
        padding: '5px 10px',
        margin: '5px',
        borderRadius: '15px',
        backgroundColor: color,
        color: 'black',
        fontSize: '0.8rem',
    });


    const modalOptions = [
        { modalTitle: 'Edit Name' },
        { modalTitle: 'Edit Graduation Date' },
        { modalTitle: 'Edit Program(s) of Study' },
        { modalTitle: 'Edit University' },
        { modalTitle: 'Edit School/Residential College' },
        { modalTitle: 'Edit Organizations' },
        { modalTitle: 'Edit Bio' },
        { modalTitle: 'Edit LinkedIn' },
    ];

    const [modalOptionsIndex, setModalOptionsIndex] = useState(null);


    const handlePencilClick = (index) => {
        setModalOptionsIndex(index);
    };

    const handleClose = () => {
        setModalShow(false);
        setModalMessage(null);
        setModalOptionsIndex(null);
        setModalEditData(fullUserData);
    }

    useEffect(() => {
        if (modalOptionsIndex != null) {
            setModalShow(true);
        }
    }, [modalOptionsIndex]);

    const handleMessageButtonClick = async () => {
        try {
            setIsIndividualMessageLoading(true);
            const response = await myAxiosInstance.post('/api/create-channel/', {recipient_slug: slug});
            setActiveChannel(response.data.channel_id);
            setChatVisible(true);

        }
        catch (error)
        {
            console.error('Error setting up channel', error.response.data);

        }
        finally{
            setIsIndividualMessageLoading(false);
        }
        


    };

    const handleSendPortfolioRequestClick = async () => {
        try {
            setSendPortfolioRequestLoading(true);
            //console.log(schedule[0].toISOString());
            let data = schedule.map((timeSlot) => (timeSlot.toISOString()));
            console.log({receiving_user: fullUserData.email, date_options: {"date_options" : data}});
            const response = await myAxiosInstance.post('/api/request-meeting/', {receiving_user: fullUserData.email, date_options: {"date_options" : data}});
            setBottomReload(bottomReload + 1);

        }
        catch (error)
        {
            console.error('Error sending portfolio request', error.response.data);
        }
        finally{
            setSendPortfolioRequestLoading(false);
        }
        
    };

    const handlePortfolioButtonClick = () => {
        if (buttonIndex == 0)
        {
            setAddToPortfolio(true);
        }
        else
        {
            navigate('/dashboard/meeting/' + fullUserData.meeting_slug);
        }

    }


    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setModalShow(false);
                setModalMessage(null);
                setModalOptionsIndex(null);
                setAddToPortfolio(false);
                setIsBottomLoading(true);
                const response = await myAxiosInstance.get('/api/public-profile/' + slug + '/');
                console.log("This is the public profile response", response);
                setFullUserData({ ...response.data, university: [response.data.university], company: [response.data.company] });
                setModalEditData({ ...response.data, university: [response.data.university], company: [response.data.company] });
                if (response.data.meeting_status === "not_meeting")
                {
                    setButtonIndex(0);
                }
                else if (response.data.meeting_status === "you_requested")
                {
                    setButtonIndex(1);
                }
                else if (response.data.meeting_status === "you_received")
                {
                    setButtonIndex(2);
                }
                else if (response.data.meeting_status === "confirmed")
                {
                    setButtonIndex(3);
                }
                else if (response.data.meeting_status === "you_rescheduled")
                {
                    setButtonIndex(4);
                }
                else if (response.data.meeting_status === "other_user_rescheduled")
                {
                    setButtonIndex(5);
                }

            } catch (error) {
                console.error('Error fetching user details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getUserDetails();


    }, [slug, bottomReload]);


    useEffect(() => {
        const updateCardSize = () => {
            if (cardRef.current) {
                const cardWidth = cardRef.current.offsetWidth;

                const size = cardWidth * 0.3;
                setPictureSize(size);
            }
        };

        updateCardSize();
        console.log("initial update");

        window.addEventListener('resize', updateCardSize);

        return () => window.removeEventListener('resize', updateCardSize);
    }, [isBottomLoading]);


    return (
        <>
            {isBottomLoading ? (
                <Loading />
            ) : (
            addToPortfolio ? 
            <Row className='justify-content-center'>
                    <Col className='col-lg-6 col-md-8'>
                    <StyledCard ref={cardRef} className='profile-joyrride'>
                    <StyledCard.Body>
                    <Container fluid="sm" className="d-flex flex-column justify-content-center">
                    <Row className='align-items-center mb-3'>
                        <Col className='text-left d-flex justify-content-between'>
                            <StyledH5>Scheduling Portfolio Meeting...</StyledH5>
                        </Col>
                    </Row>
                    <Scheduler status={"propose"} proposedTimes={schedule} setProposedTimes={setSchedule}/>
                    {/*<ScheduleSelector
        selection={schedule}
        numDays={5}
        minTime={8}
        maxTime={22}
        hourlyChunks={1}
        onChange={handleChange}
        timeFormat={'h:mma'}
      />*/}
                    <Row className='d-flex justify-content-center mt-3'>
                    <Col md={6} className='d-flex justify-content-center'>
                        
                            {/*<StyledButton className='mr-2 w-50' onClick={() => {setAddToPortfolio(false)}}>{"Cancel"}</StyledButton>*/}
                            <StyledButton className='w-50' onClick={handleSendPortfolioRequestClick}>{sendPortfolioRequestLoading ? <Loading /> : <>Send</>}</StyledButton>
                                            
                        </Col>
                    </Row>
            </Container>
            </StyledCard.Body>
            </StyledCard>
            </Col>
            </Row>

            
            
            :

                <Row className='justify-content-center'>
                    <Col className='col-lg-4 col-md-6'>
                        <StyledCard ref={cardRef} className='profile-joyrride'>
                        {userSlug === slug && (
                        <div className='w-100 d-flex justify-content-end py-2 px-2'>
                            <div className='icon-container'><Pencil onClick={() => handlePencilClick(0)} /></div>
                            
                        </div>
            )}
                            <StyledCard.Body>


                                <Container fluid="sm" className="d-flex flex-column justify-content-center">

                                    <Row className='justify-content-center mb-3'>
                                        <ProfilePicture name={(fullUserData.first_name + " " + fullUserData.last_name)} size={pictureSize} graduation_date={fullUserData.graduation_date} picture_link={fullUserData.profile_picture}></ProfilePicture>
                                    </Row>

                                    {<Row className='justify-content-center mb-3'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div className='mx-2 icon-container'>
                                                <Linkedin
                                                    onClick={() => fullUserData.linkedin_url.length > 0
                                                        ? window.open(fullUserData.linkedin_url.startsWith('www.')
                                                            ? "https://" + fullUserData.linkedin_url
                                                            : fullUserData.linkedin_url)
                                                        : null}
                                                />
                                            </div>
                                            <div className='mx-2 icon-container'>
                                                <a href={`mailto:${fullUserData.email}`}>
                                                    <Envelope />
                                                </a>
                                            </div>
                                            
                                        </div>
                                    </Row>}
                                    
                                   

                                    <Row className='align-items-center mb-3'>
                                        <Col className='text-left d-flex justify-content-between'>
                                            <StyledH5>{fullUserData.first_name + " " + fullUserData.last_name}{fullUserData.company[0] !== "" ? " - " + fullUserData.company[0] : ""}{fullUserData.is_verified ? <PatchCheck className='ml-2' style={{color: 'green'}}/> : <></>} </StyledH5>
                                        </Col>
                                    </Row>
                                    

                                    
                                    


                                    <Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                        <Col className='text-left d-flex justify-content-between'>


                                            <div>{fullUserData.university[0]}

                                            </div>
                                            
                                        </Col>
                                    </Row>


                                    <Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                        <Col className='text-left d-flex justify-content-between'>


                                            
                                            <div>
                                                <i>
                                                {fullUserData.major_or_grad_program.map((major) => (
                                                    <>{major}, </>
                                                ))}
                                                {formatGraduationDateSummer(fullUserData.graduation_date)}</i>

                                            </div>
                                            
                                        </Col>
                                    </Row>

                                    {fullUserData.bio.length > 0 ?
                                        <Row className='align-items-center mb-3' style={{ flexWrap: 'nowrap' }}>
                                            <Col className='text-left d-flex justify-content-between'>


                                                <div>{fullUserData.bio}

                                                </div>
                                                
                                                
                                            </Col>
                                        </Row> : <></>}
                                        {buttonIndex !== 6 &&
                                    <Row className='justify-content-center mb-3'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <StyledButton onClick={handlePortfolioButtonClick}>{buttonLabels[buttonIndex]}</StyledButton>
                                            
                                        </div>
                                    </Row>}
                                </Container>
                            </StyledCard.Body>
                        </StyledCard>
                        {modalOptionsIndex != null ? <Edit show={modalShow}
                            setShow={setModalShow}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                            bottomReload={bottomReload}
                            setBottomReload={setBottomReload}
                            reload={reload}
                            setReload={setReload}
                            modalTitle={modalOptions[modalOptionsIndex].modalTitle}
                            data={fullUserData}
                            handleClose={handleClose}
                            modalEditData={modalEditData}
                            setModalEditData={setModalEditData} /> : <></>}
                        <BioModal show={showBioModal} onHide={() => setShowBioModal(false)} bio={fullUserData.bio} first_name={fullUserData.first_name} />
                    </Col>
                </Row>


            )}
        </>
    );
}

export default UserProfile;

