import { useState, useEffect, useRef } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Nav, Row, Col, Container, Navbar, Button } from 'react-bootstrap';
import { StyledCard, StyledH4, StyledParagraph } from './StyledBootstrap';
import UserPreviewCard from './UserPreviewCard';
import PoolPreviewCard from './PoolPreviewCard';
import SearchUsers from './SearchUsers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHandshake, faUser, faSignOut, faCreditCard, faPlus, faGear, faQuestion, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import './Dashboard.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logo_navy from './Images/logo_navy.png';
import logo_white from './Images/logo_white.png';
import { useAuth } from './AuthContext';
import { myAxiosInstance } from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import './PublicHome.css';
import {useTracking} from 'react-tracking';

// Placeholder data
const placeholderData = {
    user: {
        firstName: 'John',
        lastName: 'Doe',
        graduationDate: '2024',
        profilePictureLink: 'path_to_placeholder_image',
        userSlug: 'john-doe',
    },
    homeUserDetails: {
        first_name: 'John',
        number_of_pools: 5,
        majors_pooled_with: 3,
        pools_captained: 1,
    },
    userPools: [
        { id: 1, name: 'Pool 1' },
        { id: 2, name: 'Pool 2' },
    ],
    suggestedPools: [
        { id: 3, name: 'Suggested Pool 1' },
        { id: 4, name: 'Suggested Pool 2' },
    ],
    suggestedUsers: [
        { first_name: 'Jane', last_name: 'Doe', id: 1 },
        { first_name: 'Bob', last_name: 'Smith', id: 2 },
    ],
};



const publicHomeButtonClick = (trackEvent, button) => {
    trackEvent({event: "Public Home Button Click", button: button})
}

function NavbarLargeScreen({ setShowReferModal }) {
    const themeMode = useTheme();
    const { firstName, lastName, graduationDate, profilePictureLink, userSlug } = placeholderData.user;
    const navigate = useNavigate();
    const {trackEvent} = useTracking({size: "Large"});
    const getBaseFontSize = () => {
        const fontSize = window.getComputedStyle(document.body).fontSize;
        return parseFloat(fontSize);
    };

    return (
        <StyledNavbar expand="sm" sticky='top'>
            <Navbar.Brand href="/home" className="d-flex ml-2 align-items-center">
                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className="d-inline-block align-top" alt="Logo" />
            </Navbar.Brand>
            <Container fluid>
                <Row className="align-items-center w-100">
                    <Col md={3} className="d-flex align-items-center">
                        <SearchUsers />
                    </Col>
                    <Col md={5} className="d-flex justify-content-between">
                        <StyledNavLink as={Link} onClick={() => publicHomeButtonClick(trackEvent, "Home")}>
                            <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                <div style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={faHome} fontSize={'1.5em'} color={themeMode.onSurface} />
                                </div>
                                <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                    Home
                                </div>
                            </div>
                        </StyledNavLink>
                        <StyledNavLink as={Link}  onClick={() => publicHomeButtonClick(trackEvent, "Meet")} to={'/dashboard/meet'}>
                            <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                <div style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={faHandshake} fontSize={'1.5em'} color={themeMode.onSurface} />
                                </div>
                                <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                    Meet
                                </div>
                            </div>
                        </StyledNavLink>
                        <StyledNavLink as={Link} to={'/dashboard/create-pool'}  onClick={() => publicHomeButtonClick(trackEvent, "Create")}>
                            <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                <div style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={faPlus} fontSize={'1.5em'} color={themeMode.onSurface} />
                                </div>
                                <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                    Create
                                </div>
                            </div>
                        </StyledNavLink>
                        <StyledNavLink as={Link} to={'/dashboard/payments'}  onClick={() => publicHomeButtonClick(trackEvent, "Payments")}>
                            <div className='d-flex flex-column justify-content-center align-content-center text-align-center'>
                                <div style={{ textAlign: 'center' }}>
                                    <FontAwesomeIcon icon={faCreditCard} fontSize={'1.5em'} color={themeMode.onSurface} />
                                </div>
                                <div style={{ textAlign: 'center', fontSize: '0.75em', color: themeMode.onSurface }}>
                                    Payments
                                </div>
                            </div>
                        </StyledNavLink>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <StyledButton onClick={() => {publicHomeButtonClick(trackEvent, "Invite"); navigate('/dashboard');}} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme === 'dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme === 'dark' ? themeMode.surface : themeMode.onPrimary }}>Invite</StyledButton>
                            <StyledDropdown >
                                <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon">
                                    <ProfilePicture name={firstName + " " + lastName} size={getBaseFontSize() * 3} graduation_date={graduationDate} picture_link={profilePictureLink} />
                                </StyledDropdown.Toggle>
                                <StyledDropdown.Menu align={'end'}>
                                    <StyledDropdown.Item as={Link} to={'/dashboard'}  onClick={() => publicHomeButtonClick(trackEvent, "Profile")}>
                                        <Row>
                                            <Col xs={2}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Col>
                                            <Col>Profile</Col>
                                        </Row>
                                    </StyledDropdown.Item>
                                    <StyledDropdown.Item as={Link} to={'/dashboard/settings'}  onClick={() => publicHomeButtonClick(trackEvent, "Settings")}>
                                        <Row>
                                            <Col xs={2}><FontAwesomeIcon icon={faGear}></FontAwesomeIcon></Col>
                                            <Col>Settings</Col>
                                        </Row>
                                    </StyledDropdown.Item>

                                    <StyledDropdown.Item as={Link} to={'/dashboard/faqs'}  onClick={() => publicHomeButtonClick(trackEvent, "FAQs")}>
                                        <Row>
                                            <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
                                            <Col>FAQs</Col>
                                        </Row>
                                    </StyledDropdown.Item>

                                    <StyledDropdown.Item onClick={() => {publicHomeButtonClick(trackEvent, "Sign In"); navigate("/signin");}}>
                                        <Row>
                                            <Col xs={2}><FontAwesomeIcon icon={faSignIn}></FontAwesomeIcon></Col>
                                            <Col>Sign In</Col>
                                        </Row>
                                    </StyledDropdown.Item>
                                </StyledDropdown.Menu>
                            </StyledDropdown>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledNavbar>
    );
}

function NavbarSmallScreen({ setShowReferModal }) {
    const [expanded, setExpanded] = useState(false);
    const themeMode = useTheme();
    const { userSlug, firstName, lastName, graduationDate } = placeholderData.user;
    const {trackEvent} = useTracking({size: "Small"});

    const navigate = useNavigate();
    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    return (
        <StyledNavbar expanded={expanded} expand="lg" sticky='top'>
            <Navbar.Brand href="/home" className="d-flex align-items-center ml-2">
                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="30rem" className="d-inline-block align-top" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle onClick={toggleNavbar} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <SearchUsers />
                <Nav.Link as={Link} to="/home" style={{ color: themeMode.onSurface }}  onClick={() => publicHomeButtonClick(trackEvent, "Home")}>Home</Nav.Link>
                <Nav.Link as={Link} to="/dashboard/meet" style={{ color: themeMode.onSurface }}  onClick={() => publicHomeButtonClick(trackEvent, "Meet")}>Meet</Nav.Link>
                <Nav.Link as={Link} to="/dashboard/create-pool" style={{ color: themeMode.onSurface }}  onClick={() => publicHomeButtonClick(trackEvent, "Create")}>Create Pool</Nav.Link>
                <Nav.Link as={Link} to="/dashboard/payments" style={{ color: themeMode.onSurface }}  onClick={() => publicHomeButtonClick(trackEvent, "Payments")}>Payments</Nav.Link>
                <Nav.Link onClick={() => {publicHomeButtonClick(trackEvent, "Invite"); navigate('/dashboard');}} style={{ color: themeMode.onSurface }}>Invite</Nav.Link>
                <StyledDropdown>
                    <StyledDropdown.Toggle>My Account</StyledDropdown.Toggle>
                    <StyledDropdown.Menu align={'start'}>
                        <StyledDropdown.Item as={Link} to={'/dashboard'}  onClick={() => publicHomeButtonClick(trackEvent, "Profile")}><FontAwesomeIcon className="mr-2" icon={faUser} />Profile</StyledDropdown.Item>
                        <StyledDropdown.Item as={Link} to={'/dashboard/settings'}  onClick={() => publicHomeButtonClick(trackEvent, "Settings")}><FontAwesomeIcon className="mr-2" icon={faGear} />Settings</StyledDropdown.Item>
                        <StyledDropdown.Item as={Link} to={'/dashboard/faqs'}  onClick={() => publicHomeButtonClick(trackEvent, "FAQs")}><FontAwesomeIcon className="mr-2" icon={faQuestion} />FAQs</StyledDropdown.Item>
                        <StyledDropdown.Item onClick={() => {publicHomeButtonClick(trackEvent, "Sign In"); navigate("/signin");}}><FontAwesomeIcon className="mr-2" icon={faSignIn} />Sign In</StyledDropdown.Item>
                    </StyledDropdown.Menu>
                </StyledDropdown>
            </Navbar.Collapse>
        </StyledNavbar>
    );
}

function PublicHome() {

    const themeMode = useTheme();
    const { first_name, number_of_pools, majors_pooled_with, pools_captained } = placeholderData.homeUserDetails;
    const { showBoundary } = useErrorBoundary();
    const isSmallScreen = useMediaQuery({ maxWidth: 1000 });
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const {Track, trackEvent} = useTracking({page: "Public Home"});
    

    //const [dataArray, setDataArray] = useState([]);
    const [suggestedPoolArray, setSuggestedPoolArray] = useState([]);
    const [suggestedUserArray, setSuggestedUserArray] = useState([]);
    const [isBottomLoading, setIsBottomLoading] = useState(true); // make this true
    //const [homeUserDetails, setHomeUserDetails] = useState(null);
    const { reload, setReload, token } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);


    const navbarRef = useRef(null);
    const containerRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate('/dashboard'); // Navigate to another page if condition is met
        }
    }, []);

    useEffect(() => {
        const getMyPoolsData = async () => {
            try {
                // console.log(await myAxiosInstance.get('/api/home/'));
                console.log("running use effect before....");
                const response = await myAxiosInstance.get('/api/guest-home/');
                console.log("running use effect after....");
                console.log(response);
                console.log("DATA", response.data);
                //setDataArray(response.data.user_pools || []);
                //setHomeUserDetails(response.data.user_details);
                setSuggestedPoolArray(response.data.suggested_pools);
                setSuggestedUserArray(response.data.suggested_users);

                console.log("this is what it is being set to: ");
                console.log(response.data.suggested_users[0]);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };
        getMyPoolsData();
    }, [reload, bottomReload]);


    useEffect(() => {
        const updateLayout = () => {
            const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
            const viewportHeight = window.innerHeight;
            const containerHeight = viewportHeight - navbarHeight;

            if (containerRef.current) {
                containerRef.current.style.marginTop = `${navbarHeight}px`; // Push content below the navbar
                containerRef.current.style.minHeight = `${containerHeight}px`; // Ensure it fills the remaining screen height
            }
        };

        updateLayout(); // Adjust layout on mount
        window.addEventListener('resize', updateLayout); // and on window resize
        trackEvent({event: "Public Home Page View"});

        return () => window.removeEventListener('resize', updateLayout); // Cleanup
    }, []); // Run once on mount



    return (
        <Track>
        <Container fluid className="px-0">
            <div ref={navbarRef} className='fixed-top-navbar'>
                {isSmallScreen ? <NavbarSmallScreen /> : <NavbarLargeScreen />}
            </div>
            <Container ref={containerRef} fluid className='py-3 d-flex flex-column justify-content-center' style={{ backgroundColor: themeMode.background }}>
                <Row className='justify-content-center' style={{ backgroundColor: themeMode.background }}>
                    <Col className='col-lg-6 col-md-8'>
                    {!isMdOrLarger && (
                            <StyledCard className="mt-3">
                                <StyledCard.Body>
                                    <StyledH5 className="mb-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                    <div style={{ display: 'flex', overflowX: 'scroll' }}>
                                        {suggestedUserArray.map((user, index) => (
                                            <div className="mx-2" key={index}>
                                                <UserPreviewCard preview_object={user} />
                                            </div>
                                        ))}
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        )}
                        <div className='position-relative mt-3'>
                            <StyledCard >
                                <StyledCard.Body>
                                    <Container fluid>
                                        <Row>
                                            <div><StyledH5><strong>My Pools</strong></StyledH5></div>
                                        </Row>
                                        <Row className='blurred-section'>
                                            {placeholderData.userPools.length === 0 ? (
                                                <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
                                                    You have not been invited to any pools. <Link to="/create-pool">Create</Link> your own or wait for an invite.
                                                </StyledParagraph>
                                            ) : (
                                                placeholderData.userPools.map((pool) => (
                                                    <Col className='mt-3 col-md-6' key={pool.id}><PoolPreviewCard data={pool} /></Col>
                                                ))
                                            )}
                                        </Row>
                                    </Container>
                                </StyledCard.Body>
                            </StyledCard>
                            <div className="sign-in-overlay">
                                <StyledButton className="sign-in-button" onClick={() => {publicHomeButtonClick(trackEvent, "My Pools"); navigate('/dashboard');}}>
                                    Sign In to View
                                </StyledButton>
                            </div></div>
                            
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <Container fluid>
                                    <Row>
                                        <div><StyledH5><strong>Featured Success Pools</strong></StyledH5></div>
                                    </Row>
                                    <Row>
                                        {suggestedPoolArray.map((pool) => (
                                            <Col className='mt-3 col-md-6' key={pool.id}><PoolPreviewCard data={pool} /></Col>
                                        ))}
                                    </Row>
                                </Container>
                            </StyledCard.Body>
                        </StyledCard>

                        
                        <div className='position-relative'>
                            <StyledCard className="mt-3">
                                <StyledCard.Body>
                                    <Container fluid>
                                        <Row className="mb-2">
                                            <div><StyledH5><strong>Statistics</strong></StyledH5></div>
                                        </Row>
                                        <div className='blurred-section'>
                                            <Row>
                                                <Col className="d-flex flex-column justify-content-center align-items-center">
                                                    <div><StyledH4><strong>{2}</strong></StyledH4></div>
                                                </Col>
                                                <Col className="d-flex flex-column justify-content-center align-items-center">
                                                    <div><StyledH4><strong>{12}</strong></StyledH4></div>
                                                </Col>
                                                <Col className="d-flex flex-column justify-content-center align-items-center">
                                                    <div><StyledH4><strong>{0}</strong></StyledH4></div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex flex-column justify-content-center">
                                                    <div className='text-center'>Signed Pools</div>
                                                </Col>
                                                <Col className="d-flex flex-column justify-content-center">
                                                    <div className='text-center'>Pooled Majors</div>  // AMOLI CHANGE
                                                </Col>
                                                <Col className="d-flex flex-column justify-content-center">
                                                    <div className='text-center'>Pools Captained</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </StyledCard.Body>
                            </StyledCard>
                            <div className="sign-in-overlay">
                                <StyledButton className="sign-in-button" onClick={() => {publicHomeButtonClick(trackEvent, "Statistics"); navigate('/dashboard');}}>
                                    Sign In to View
                                </StyledButton>
                            </div></div>
                        <div className='position-relative'>
                            <StyledCard className="mt-3">
                                <StyledCard.Body>
                                    <Container fluid>
                                        <Row>
                                            <div><StyledH5><strong>My Meetings</strong></StyledH5></div>
                                        </Row>
                                        <Row className='blurred-section'>
                                            <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
                                                You have not been invited to any meetings yet. In the meanwhile, make sure to <Link to="/dashboard/meet">update your availability</Link> in the Meet Section.
                                            </StyledParagraph>
                                        </Row>
                                    </Container>
                                </StyledCard.Body>
                            </StyledCard>
                            <div className="sign-in-overlay">
                                <StyledButton className="sign-in-button"  onClick={() => {publicHomeButtonClick(trackEvent, "My Meetings"); navigate('/dashboard');}}>
                                    Sign In to View
                                </StyledButton>
                            </div></div>


                    </Col>
                    {isMdOrLarger && (
                        <Col className="col-lg-3 col-md-4" style={{ position: 'relative' }}>
                            <StyledCard className="justify-content-center" style={{ height: '85vh', position: 'sticky', top: '100px' }}>
                                <StyledH5 className="mt-3" style={{ textAlign: "center" }}><strong>Suggested Users</strong></StyledH5>
                                <StyledCard.Body className='justify-content-center' style={{ overflowY: 'auto', padding: '5px' }}>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        {suggestedUserArray.map((user, index) => (
                                            <div className="mx-1 my-2" key={index}>
                                                <UserPreviewCard preview_object={user} />
                                            </div>
                                        ))}
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </Col>
                    )}
                </Row>
            </Container>
            <StyledButton onClick={() => {publicHomeButtonClick(trackEvent, "Chat"); navigate('/dashboard');}} style={{ position: 'fixed', bottom: '2vh', right: 20, zIndex: 1000 }}>
                Chat
            </StyledButton>
        </Container>
        </Track>
    );
}

export default PublicHome;
