import { StyledModal, StyledButton, StyledParagraph } from "./StyledBootstrap";

function TutorialModal({ show, tutorialPart, onNext, onSkip, onBack }) {
  const renderContent = () => {
    switch (tutorialPart) {
      case 1:
        return (
          <>
            <StyledParagraph>Welcome to Prospinity. Here's what you should know:</StyledParagraph>
            <StyledParagraph>
              <strong>AI-matched weekly 1:1 meetings:</strong> After you have completed your profile, click on the Meetings tab and add your preferred meeting times. Our AI matches you weekly with peers for a 30-minute meeting based on your background and goals.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Creating or Joining Your Pool:</strong> Click “Create Pool,” choose a Success Pool package, and send invitations to the members you want. You are considered the "pool captain" of pools you create. Alternatively, you can accept an invite to an existing pool created by another captain. Browse existing public pools in the Featured Pools section.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Manage Your Pool:</strong> Track and manage your active pools on your home page, and join new pools.
            </StyledParagraph>
            <StyledParagraph>
              <strong>Support and Resources:</strong> Have questions or need help? Check out our FAQs! You can also email support@prospinity.com for any other questions.
            </StyledParagraph>
          </>
        );
      /*case 1:
        return (
          <>
            <StyledParagraph>Welcome to Prospinity. Here's how to start:</StyledParagraph>
            <StyledParagraph><strong>Creating or Joining Your Pool:</strong> Click “Create Pool”, choose a Success Pool package, and send invitations to members you want. You are considered the "pool captain" of pools you create. Or accept an invite to an existing pool created by another captain.</StyledParagraph>
            <StyledParagraph><strong>Invitation Period:</strong> Before the invitation deadline, the pool is flexible. Members can join and leave, and the captain can change the terms of the pool. But after the invitation deadline, the pool is locked. The terms are set and no one else can join.</StyledParagraph>
          </>
        );
      case 2:
        return (
          <>
            
            <StyledParagraph><strong>Signing Period:</strong> After the invitation deadline expires, the pool is in the Signing Period. Members can choose to sign the final success pooling agreement with the set terms and members.</StyledParagraph>
            <StyledParagraph><strong>Activation:</strong> If a certain number of members sign the agreement by the signing deadline, the pool activates and the agreement goes into effect between them. Members that did not sign are not part of the pool.</StyledParagraph>
            <StyledParagraph><strong>Tryout:</strong> Even after signing, you have a month (or sometimes longer) to "try out" the pool. If you change your mind, you can cancel your signature. This is to make sure you are comfortable and have enough time to make your decision.</StyledParagraph>
          </>
        );
      case 3:
        return (
          <>
            <StyledParagraph>Once you are in a pool, engage with your pool members and the larger Prospinity community.</StyledParagraph>
            <StyledParagraph><strong>Manage Your Pool:</strong> Track and manage your active pools on your home page, and join new pools.</StyledParagraph>
            <StyledParagraph><strong>Resources:</strong> Engage with our Slack community and make career asks in your private pool channel. Over time, we will add exclusive resources and networking opportunities for Prospinity pool members.</StyledParagraph>
            <StyledParagraph><strong>Support and Resources:</strong> Questions or need help? Check out our FAQs! Also feel free to email support@prospinity.com for any other questions.</StyledParagraph>
          </>
        );*/
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  return (
    <StyledModal show={show} onHide={onSkip} centered>
      <StyledModal.Header>
        <StyledModal.Title>Introduction to Prospinity</StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>{renderContent()}</StyledModal.Body>
      <StyledModal.Footer>
        {tutorialPart > 1 ?
          <StyledButton variant="secondary" onClick={onBack}>Back</StyledButton> : <></>}
        <StyledButton variant="primary" onClick={onNext}>
          {tutorialPart < 1 ? "Next" : "Start Guided Tour"}
        </StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  );
};

export default TutorialModal;






/*function TutorialModal({ show, tutorialPart, onNext, onSkip, onBack }) {
  const renderContent = () => {
    switch (tutorialPart) {
      case 1:
        return (
          <>
            <StyledParagraph>Welcome to Prospinity. Here's how to start:</StyledParagraph>
            <StyledParagraph><strong>Creating or Joining Your Pool:</strong> Click “Create Pool”, choose a Success Pool package, and send invitations to members you want. You are considered the "pool captain" of pools you create. Or accept an invite to an existing pool created by another captain.</StyledParagraph>
            <StyledParagraph><strong>Invitation Period:</strong> Before the invitation deadline, the pool is flexible. Members can join and leave, and the captain can change the terms of the pool. But after the invitation deadline, the pool is locked. The terms are set and no one else can join.</StyledParagraph>
          </>
        );
      case 2:
        return (
          <>
            
            <StyledParagraph><strong>Signing Period:</strong> After the invitation deadline expires, the pool is in the Signing Period. Members can choose to sign the final success pooling agreement with the set terms and members.</StyledParagraph>
            <StyledParagraph><strong>Activation:</strong> If a certain number of members sign the agreement by the signing deadline, the pool activates and the agreement goes into effect between them. Members that did not sign are not part of the pool.</StyledParagraph>
            <StyledParagraph><strong>Tryout:</strong> Even after signing, you have a month (or sometimes longer) to "try out" the pool. If you change your mind, you can cancel your signature. This is to make sure you are comfortable and have enough time to make your decision.</StyledParagraph>
          </>
        );
      case 3:
        return (
          <>
            <StyledParagraph>Once you are in a pool, engage with your pool members and the larger Prospinity community.</StyledParagraph>
            <StyledParagraph><strong>Manage Your Pool:</strong> Track and manage your active pools on your home page, and join new pools.</StyledParagraph>
            <StyledParagraph><strong>Resources:</strong> Engage with our Slack community and make career asks in your private pool channel. Over time, we will add exclusive resources and networking opportunities for Prospinity pool members.</StyledParagraph>
            <StyledParagraph><strong>Support and Resources:</strong> Questions or need help? Check out our FAQs! Also feel free to email support@prospinity.com for any other questions.</StyledParagraph>
          </>
        );
      default:
        return <StyledParagraph>Unknown part!</StyledParagraph>;
    }
  };

  return (
    <StyledModal show={show} onHide={onSkip} centered>
      <StyledModal.Header>
        <StyledModal.Title>Introduction to Prospinity</StyledModal.Title>
      </StyledModal.Header>
      <StyledModal.Body>{renderContent()}</StyledModal.Body>
      <StyledModal.Footer>
        {tutorialPart > 1 ? 
        <StyledButton variant="secondary" onClick={onBack}>Back</StyledButton> : <></>}
        <StyledButton variant="primary" onClick={onNext}>
          {tutorialPart < 3 ? "Next" : "Finish"}
        </StyledButton>
      </StyledModal.Footer>
    </StyledModal>
  );
};

export default TutorialModal;*/



