import { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown, FormGroup, Card } from 'react-bootstrap';
import logo from './Images/Logo1.png';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { apiP, errorJSX } from './CriticalFunctions';
import MultiSelect from './AdaptedMultiSelect';
import SingleSelect from './AdaptedSingleSelect';
import { FormInput } from './FormComponents';
import { useAuth } from './AuthContext';
import { useErrorBoundary } from "react-error-boundary";
import parsePhoneNumber from 'libphonenumber-js'

import { useTheme } from 'styled-components';
import { StyledForm, StyledH2, StyledH3, StyledH4, StyledModal, StyledRangeInput, StyledFormControl, StyledCard, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph, StyledPhoneInput, StyledDropdown, StyledTextArea, StyledFormCheck, StyledFormSelect } from './StyledBootstrap';
import FormTooltip from './FormTooltip';
import logo_white from './Images/logo_white.png';
import logo_navy from './Images/logo_navy.png';

import ProfilePictureUpload from './ProfilePictureUpload';
import { useTracking } from 'react-tracking';
import { faSignOut, faBars } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import ProgressBar from "@ramonak/react-progress-bar";
import { myAxiosInstance } from './axiosConfig';
import Loading from './Loading';
import VerificationInput from "react-verification-input";



function FinishRegistration() {
    const { handleLogout, registrationStage, setRegistrationStage, userEmail } = useAuth();
    const themeMode = useTheme();
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(registrationStage == 1 ? 0 : registrationStage == 5 ? 6 : registrationStage == 6 ? 7 : registrationStage);

    const firstNameRef = useRef(null);
    const universityRef = useRef(null);
    const positionRef = useRef(null);
    const phoneNumberRef = useRef(null);




    const [oneInfo, setOneInfo] = useState({ first_name: "", last_name: "" });
    const [twoInfo, setTwoInfo] = useState({
        university: [],
        major_or_grad_program: [],
        graduation_date: null
    });
    const [threeInfo, setThreeInfo] = useState({
        company: [],
        role: ""
    });
    const [fourInfo, setFourInfo] = useState({
        phone: null
    });

    const [verificationCode, setVerificationCode] = useState(null);

    const [sessionUUID, setSessionUUID] = useState(null);


    const [errors, setErrors] = useState({ first_name: false, last_name: false, university: false, major_or_grad_program: false, graduation_date: false, company: false, role: false, phone: false, profile_picture: false, verification_code: false });

    const { showBoundary } = useErrorBoundary();


    const [stageLoading, setStageLoading] = useState(true);
    const [nextLoading, setNextLoading] = useState(false);

    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstNameRef.current) {
            firstNameRef.current.focus();
        }
        if (universityRef.current) {
            universityRef.current.focus();
        }
        if (positionRef.current) {
            positionRef.current.focus();
        }
        if (phoneNumberRef.current) {
            phoneNumberRef.current.focus();
        }
    }, [step, stageLoading]);

    const { trackEvent, Track } = useTracking(
        { event: "Finish Registration" },

    );
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setStageLoading(true);
                if (registrationStage > step) {
                    if (step == 1) {
                        const response = await myAxiosInstance.get('/api/finish-registration-one/');
                        setOneInfo({ first_name: response.data.first_name, last_name: response.data.last_name });

                    }
                    if (step == 2) {
                        const response = await myAxiosInstance.get('/api/finish-registration-two/');
                        setTwoInfo({ university: [response.data.university], major_or_grad_program: response.data.major_or_grad_program, graduation_date: response.data.graduation_date });
                    }
                    if (step == 3) {
                        const response = await myAxiosInstance.get('/api/finish-registration-three/');
                        setThreeInfo({ company: [response.data.company], role: response.data.role });
                    }
                    if (step == 4) {
                        const response = await myAxiosInstance.get('/api/finish-registration-four/');
                        setFourInfo({ phone: response.data.phone });
                    }
                }



            } catch (error) {
                showBoundary(error);
            } finally {
                setStageLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();



    }, [step]);




    const years = Array.from({ length: 31 }, (v, i) => i + 2020);


    const [introModalStep, setIntroModalStep] = useState(1);

    const handleLeft = () => {
        if (introModalStep == 1) {
            handleClose();
        }
        else {
            setIntroModalStep(1);
        }

    }
    const handleRight = () => {
        if (introModalStep == 1) {
            setIntroModalStep(2);
        }
        else {
            handleClose();
        }

    }



    const [finishModalShow, setFinishModalShow] = useState(true);

    const handleClose = () => {
        setFinishModalShow(false);
    };

    const navigate = useNavigate();




    const handlePhoneChange = (value) => {
        // Custom handler for phone number input

        setFourInfo(prevData => ({
            ...prevData,
            phone: value ? value : "",
        }));
    };






    const isValidPhoneNumber = (phoneNumber) => {

        try {
            const parsed = parsePhoneNumber(phoneNumber);
            console.log("parsed", parsed);
            console.log("is valid", parsed.isValid());

            // Define the regex for a valid phone number
            return parsed.isValid();
        }
        catch {
            setErrors({ ...errors, phone: true });
        }

    };




    const handleNext = async (verCode) => {
        setNextLoading(true);
        trackEvent({ button: "Next", step: step });
        let goNext = true;
        try {

            if (step == 1) {
                const response = await myAxiosInstance.put('/api/finish-registration-one/', oneInfo);
                setErrors({ ...errors, first_name: false, last_name: false });
            }
            if (step == 2) {
                let data = {
                    ...twoInfo,
                    university: twoInfo.university.length === 0 ? '' : twoInfo.university[0],
                };
                const response = await myAxiosInstance.put('/api/finish-registration-two/', data);
                setErrors({ ...errors, university: false, major_or_grad_program: false, graduation_date: false });

            }
            if (step == 3) {
                let data = {
                    ...threeInfo,
                    company: threeInfo.company.length === 0 ? '' : threeInfo.company[0] === "Other" ? '' : threeInfo.company[0],
                };
                const response = await myAxiosInstance.put('/api/finish-registration-three/', data);
                setErrors({ ...errors, company: false, role: false });
            }
            if (step == 4) {
                console.log("phone number", fourInfo.phone);
                
                if (!isValidPhoneNumber(fourInfo.phone)) {
                    setErrors({ ...errors, phone: true });
                    goNext = false;
                }
                else
                {
                    const response = await myAxiosInstance.put('/api/finish-registration-four/', {phone: fourInfo.phone});
                    console.log("phone api response", response);
                    console.log(response.data.session_uuid);
                    setSessionUUID(response.data.session_uuid);
                    setErrors({ ...errors, phone: false});
                }
            }
            if (step == 5)
            {
                const response = await myAxiosInstance.put('/prospinity-auth/phone-number/verify/', {session_uuid: sessionUUID, verification_code: verCode});
                setErrors({ ...errors, verification_code: false});
                
            }
            /*if (step == 6) {

                const response = await myAxiosInstance.put('/api/finish-registration-four/', fourInfo);
                setErrors({ ...errors, first_name: false, last_name: false });
                trackEvent({ button: "Submit" });
                //window.location.href = '/dashboard';


            }*/
            if (goNext) {
                setStep(step + 1);
                setRegistrationStage(step + 1);
            }

        }
        catch (error) {

            console.log("error", error);

            if (error.response && error.response.data) {
                // Construct the error list JSX and pass it to setMessage
                let tempErrors = { first_name: false, last_name: false, university: false, major_or_grad_program: false, graduation_date: false, company: false, role: false, phone: false, profile_picture: false }
                for (const [key, value] of Object.entries(error.response.data)) {
                    tempErrors = { ...tempErrors, [key]: true };
                }
                setErrors(tempErrors);

            }

            setMessage(errorJSX(error));



        }
        finally {
            setNextLoading(false);
        }
    };

    const handlePrevious = () => {
        if (step > 0) {
            trackEvent({ button: "Back", step: step });
            setStep(step - 1);
        }
    };


    const handleSubmitPhoneVerification = (value) => {
        console.log("verification code value", value);
        //setVerificationCode(value);
        handleNext(value);
    }






    return (
        <Track>
            <Container fluid style={{ minHeight: '100vh', backgroundColor: themeMode.background }}>
                <Row className="align-items-center justify-content-end w-100">
                    <Col md={4} className="d-flex justify-content-end">
                        <StyledDropdown className='mt-2'>
                            <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon">
                                {/*<FontAwesomeIcon icon={faUser} />*/}
                                <FontAwesomeIcon icon={faBars} />
                                {/*<ProfilePicture name={userFirstName + " " + userLastName} size={getBaseFontSize() * 3} graduation_date={userGraduationDate} picture_link={profilePictureLink}/>*/}
                            </StyledDropdown.Toggle>
                            <StyledDropdown.Menu align={'end'}>
                                <StyledDropdown.Item>
                                    {userEmail}
                                </StyledDropdown.Item>
                                <StyledDropdown.Item onClick={() => { trackEvent({ button: "Logout", step: step }); handleLogout(); }}>
                                    <Row className='d-flex justify-content-center'>
                                        <div className='text-center'>
                                            <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon>  Logout
                                        </div>
                                    </Row>
                                    {/*<Row>

                                        <Col xs={2}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></Col>
                                        <Col className='text-right'>Logout</Col>

    </Row>*/}
                                </StyledDropdown.Item>
                            </StyledDropdown.Menu>
                        </StyledDropdown>
                    </Col>

                </Row>
                <Row className='w-100 justify-content-center ' style={{ minHeight: '80vh' }}>
                    <Col className='col-md-4 d-flex flex-column justify-content-center'>
                        <Row className='justify-content-center mb-3'>
                            <Col md={10} className="text-center">
                                <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
                                <div className='d-flex justify-content-center w-100'>
                                    <div className='w-50 mt-4'>
                                        <ProgressBar completed={(step + 1) * 100.0 / 7.0} bgColor={themeMode.primary} customLabel={" "} height={"10px"} transitionDuration='0.3s' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <StyledCard className='my-3'>
                            <StyledCard.Body>
                                {stageLoading ? <Loading /> :
                                    <>
                                        {step == 0 && (
                                            <>
                                                <StyledH4>Welcome to Prospinity!</StyledH4>
                                                <StyledParagraph>Please take <strong>five minutes</strong> and finish your account to get started.</StyledParagraph>

                                                <StyledParagraph>By finishing an account, there is <strong>no commitment</strong> to join a pool or take a meeting. You will simply receive access to our pooling and meeting ecosystem and get to choose which pools and meetings you join.</StyledParagraph>
                                            </>

                                        )}
                                        {step === 1 && (
                                            <>
                                                <StyledH4 className='mb-4'><strong>What's your name?</strong></StyledH4>
                                                <div className='mb-4'>
                                                    <StyledFormControl
                                                        type="text"
                                                        placeholder={"First Name"}
                                                        value={oneInfo.first_name}
                                                        onChange={(e) => setOneInfo({ ...oneInfo, first_name: e.target.value })}
                                                        onClick={() => setErrors({ ...errors, first_name: false })}
                                                        ref={firstNameRef}
                                                        hasError={errors.first_name}
                                                    />
                                                    {errors.first_name ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid first name.</StyledParagraph> : <></>}
                                                </div>
                                                <div className='mb-4'>
                                                    <StyledFormControl
                                                        type="text"
                                                        placeholder={"Last Name"}
                                                        value={oneInfo.last_name}
                                                        onChange={(e) => setOneInfo({ ...oneInfo, last_name: e.target.value })}
                                                        hasError={errors.last_name}
                                                        onClick={() => setErrors({ ...errors, last_name: false })}
                                                    />
                                                    {errors.last_name ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid last name.</StyledParagraph> : <></>}
                                                </div>




                                            </>
                                        )}
                                        {step === 2 && (
                                            <>
                                                <StyledH4 className='mb-4'><strong>Tell us about your educational journey.</strong></StyledH4>

                                                <SingleSelect
                                                    endpoint='search-universities'
                                                    value={twoInfo.university}
                                                    onChange={(selected) => {
                                                        setTwoInfo({ ...twoInfo, university: selected });
                                                    }}
                                                    label="Search Your University"
                                                    autofocus={true}
                                                    hasError={errors.university}
                                                    onClick={() => setErrors({ ...errors, university: false })}
                                                />
                                                {errors.university ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid university.</StyledParagraph> : <></>}



                                                <div className='mb-4 mt-4'>

                                                    <StyledFormSelect
                                                        defaultValue=""
                                                        aria-label="Select graduation year"
                                                        required
                                                        value={twoInfo.graduation_date || ""}
                                                        onChange={(e) => setTwoInfo({ ...twoInfo, graduation_date: e.target.value })}
                                                        hasError={errors.graduation_date}
                                                        onClick={() => setErrors({ ...errors, graduation_date: false })}
                                                    >
                                                        <option value="" disabled>Select Graduation Year</option>
                                                        {years.map(year => (
                                                            <option key={year} value={year}>{year}</option>
                                                        ))}
                                                    </StyledFormSelect>
                                                    {errors.graduation_date ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid graduation date.</StyledParagraph> : <></>}




                                                </div>

                                                <div>

                                                    <MultiSelect
                                                        endpoint='search-degrees'
                                                        value={twoInfo.major_or_grad_program}
                                                        onChange={(selected) => {
                                                            setTwoInfo({ ...twoInfo, major_or_grad_program: selected });
                                                        }}
                                                        label="Degree(s)"
                                                        extra={<><span className="text-danger">*</span><FormTooltip message="This is your major, degree program, etc. If you can't find yours, please choose the closest one and you can change it later - we are constantly updating our database." /></>}
                                                        hasError={errors.major_or_grad_program}
                                                        onClick={() => setErrors({ ...errors, major_or_grad_program: false })}

                                                    />
                                                    {errors.major_or_grad_program ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please select the degree program closest to what you are studying.</StyledParagraph> : <></>}
                                                </div>

                                            </>
                                        )

                                        }
                                        {step === 3 && (
                                            <>
                                                <StyledH4 className='mb-4'><strong>Most important professional experience?</strong></StyledH4>
                                                <div className='mb-4'>
                                                    <StyledFormControl
                                                        type="text"
                                                        placeholder={"Position (ex. Incoming Investment Intern)"}
                                                        value={threeInfo.role}
                                                        onChange={(e) => setThreeInfo({ ...threeInfo, role: e.target.value })}
                                                        ref={positionRef}
                                                        hasError={errors.role}
                                                        onClick={() => setErrors({ ...errors, role: false })}
                                                    />
                                                    {errors.role ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid company position.</StyledParagraph> : <></>}

                                                </div>

                                                <div className='mb-4'>
                                                    <SingleSelect
                                                        endpoint='search-companies'
                                                        value={threeInfo.company}
                                                        onChange={(selected) => {
                                                            setThreeInfo({ ...threeInfo, company: selected });
                                                        }}
                                                        label="Company (ex. Google, Other)"
                                                        other={true}
                                                        extra={<FormTooltip message="The internship/career experience that you have had (or are about to have) you would like to highlight most. If you can't find yours, please choose the closest one and you can change it later - we are constantly updating our database." />}
                                                        hasError={errors.company}
                                                        onClick={() => setErrors({ ...errors, company: false })}
                                                    />
                                                    {errors.company ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid company.</StyledParagraph> : <></>}

                                                </div>
                                            </>
                                        )}
                                        {step == 4 && <>

                                            <StyledH4 className='mb-4'><strong>What's your phone number?</strong></StyledH4>


                                            <div className='mb-4'>
                                                <StyledPhoneInput
                                                    placeholder="Enter phone number"
                                                    value={fourInfo.phone}
                                                    onChange={handlePhoneChange}
                                                    defaultCountry="US"
                                                    name="phone"
                                                    className="custom-phone-input"
                                                    ref={phoneNumberRef}
                                                    hasError={errors.ohone}
                                                    onClick={() => setErrors({ ...errors, phone: false })}
                                                />
                                            </div>
                                            {errors.phone ? <StyledParagraph className='mt-3 text-center' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid phone number not currently linked to a Prospinity account.</StyledParagraph> : <></>}



                                        </>}
                                        {step == 5 && <>

                                            <StyledH4 className='mb-4'><strong>Enter the six digit code we sent to your device.</strong></StyledH4>


                                            <div className='mb-4 d-flex justify-content-center'>
                                                
                                                <VerificationInput length={6} placeholder=' ' autoFocus={true} validChars='0-9' onComplete={handleSubmitPhoneVerification}/>
                                            </div>
                                            {errors.verification_code ? <StyledParagraph className='mt-3 text-center' style={{ fontSize: '0.7rem', color: themeMode.error }}>Incorrect Verification Code.</StyledParagraph> : <></>}
                                            {<StyledParagraph onClick={handlePrevious}className='mt-3 text-center' style={{ fontSize: '0.7rem' }}>Haven't received it or not working? Click to re-enter your phone number.</StyledParagraph>}



                                        </>}
                                        {step == 6 && <>

                                            <StyledH4 className='mb-4'><strong>We need a profile pic...</strong></StyledH4>
                                            <ProfilePictureUpload registration={true} handleNextRegistration={handleNext} />
                                            {errors.profile_picture ? <StyledParagraph className='mt-3 text-center' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please upload a valid profile picture.</StyledParagraph> : <></>}

                                        </>}</>}

                                {step == 7 && <>


                                    <StyledH4 className='mb-4'><strong>Last step!</strong></StyledH4>
                                    <StyledParagraph>An verification email was sent to <strong>{userEmail}</strong>! After you verify your email, feel free to visit your <a href="/dashboard">dashboard</a>.</StyledParagraph>

                                </>}






                            </StyledCard.Body>
                        </StyledCard>
                        {step != 7 ?
                            <Row className='d-flex justify-content-center mt-1'>
                                <Col md={8} className='d-flex justify-content-center'>
                                    {step == 0 ?
                                        <StyledButton onClick={handleNext} className='w-100'>
                                            {nextLoading ? <Loading /> : <>Continue</>}

                                        </StyledButton>
                                        : <></>}
                                    {step != 0 ?
                                        <>
                                            <StyledButton onClick={handlePrevious} className='mr-2 w-50' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
                                                Back
                                            </StyledButton>
                                            <StyledButton onClick={handleNext} className='ml-2 w-50'>
                                                {nextLoading ? <Loading /> : <>{step == 6 ? <>Finish</> : step == 4 ? <>Send Code</> : step == 3 ? <>Skip</> : <>Continue</>}</>}

                                            </StyledButton></>
                                        : <></>}

                                </Col>
                            </Row> : <></>}
                    </Col>
                </Row>
            </Container>


            {/*<StyledModal show={message !== ''} centered>
                <StyledModal.Header>Please correct the following items...</StyledModal.Header>


                <StyledModal.Body>{message}</StyledModal.Body>
                <StyledModal.Footer>
                    <StyledButton onClick={() => {

                        setMessage('');
                    }}>Fix</StyledButton>

                </StyledModal.Footer>

                </StyledModal>*/}

        </ Track>


    );
}

export default FinishRegistration;
